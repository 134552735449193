import React from "react"
import styled from "styled-components"

type PositionStampProps = {
  lang?: string
}

const Wrapper = styled.div`
  position: absolute;
  top: 15rem;
  left: 15%;
  width: 70%;
  height: 10rem;
  border: 5px dashed ${(props) => props.theme.color.red};
  border-radius: 10px;
  background-color: ${(props) => props.theme.color.white};
  display: flex;
  transform: rotate(-23deg);
  z-index: 1;
  opacity: 0.75;
`

const WrapperInner = styled.div`
  color: ${(props) => props.theme.color.red};
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: auto;
  opacity: 1;
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    font-size: 3rem;
  }
`

const PositionStamp: React.FC<PositionStampProps> = ({ lang }) => {
  const isEnLanguage = lang === "en"
  const textPositionStamp = isEnLanguage ? "filled" : "obsazeno"
  return (
    <Wrapper lang={lang}>
      <WrapperInner>{textPositionStamp}</WrapperInner>
    </Wrapper>
  )
}

export default PositionStamp
