import React from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "@igloonet-web/shared-ui/themes/igloonet"

import {
  Slider,
  TitleH2Center,
  PaddingHalfWrapper,
  Content,
  Link,
  ContactFormJobs,
} from "@igloonet-web/shared-ui"
import Benefits from "../components/jobs/benefits"
import PositionStamp from "../components/position-stamp"

import ProfileKepi from "../images/team/profile/kepi.jpg"
import ProfileBara from "../images/team/profile/bara.jpg"
import ProfileWicki from "../images/team/profile/wicki.jpg"
import JobHeader from "../components/jobs/job-detail-header"

const JobDetailMainWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  z-index: 1050;
`

const JobDetailWrapper = styled.div`
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  max-width: 944px;
  margin: 1.75rem auto;
  z-index: 2000;
  background: #ffffff;
`

const JobDetailWindow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`

const JobDetailLanguageVersion = styled(Link)`
  line-height: 1.5;
  display: block;
  text-align: right;
  padding-right: 2.5em;
  padding-top: 1em;
`

const JobDetailBody = styled.div`
  position: relative;
`

const JobDetailContent = styled.div`
  display: block;
  margin: auto;
  line-height: 1.5;
  max-width: 690px;
`

const LinuxAdminKaJobDetail = () => {
  const personSlider = [
    {
      id: 1,
      imageSrc: ProfileBara,
      name: "Bára Michalčíková",
      position: "administrativa",
    },
    {
      id: 2,
      imageSrc: ProfileKepi,
      name: "Kepi",
      position: "Vedoucí hostingu & devops",
    },
    {
      id: 3,
      imageSrc: ProfileWicki,
      name: "Martin Taraba",
      position: "podpora na hostingu",
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <JobDetailWindow />

      <JobDetailMainWrapper>
        <JobDetailWrapper>
          <JobHeader />

          <JobDetailBody>
            <PositionStamp />
            <JobDetailLanguageVersion to="/en-linux-admin">
              switch to english version
            </JobDetailLanguageVersion>
            <JobDetailContent className="px-2 px-lg-0">
              <PaddingHalfWrapper>
                <h1 className="d-block my-auto pb-5">Linux admin(ka)?</h1>
                <p>
                  <strong>
                    Ahoj, jsme <Link to="/">igloonet</Link>
                  </strong>{" "}
                  a osmnáct let děláme hosting a vrtáme se v serverech pro nás i
                  naše zákazníky.
                </p>
                <p>
                  <strong>TL;DR:</strong> Hledáme šikovnou full-time parťačku či
                  parťáka do menšího než malého týmu v Brně. Někoho, kdo je
                  zodpovědný, spolehlivý, s lecčím si poradí a pomůže nám jak
                  s&nbsp;běžnými adminovskými starostmi, tak s dalším rozvojem.
                </p>
                <p>
                  <strong>Poznámka ze startu:</strong> Pokud tě inzerát nadchne
                  a práce u nás se ti zdá jako sen, ozvi se, i když si na to
                  netroufáš. Kolegy si pečlivě vybíráme, ale záleží nám hlavně
                  na přístupu a charakteru. Znalosti a zkušenosti můžeme
                  dotáhnout spolu.
                </p>
              </PaddingHalfWrapper>

              <Content>
                <PaddingHalfWrapper>
                  <TitleH2Center>Proč je tato pozice důležitá?</TitleH2Center>
                </PaddingHalfWrapper>
                <ul>
                  <li>
                    I díky tobě si lidé pořídí{" "}
                    <a
                      href="https://www.snowboard-zezula.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      nový prkno
                    </a>
                    ,{" "}
                    <a
                      href="https://www.planetaher.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      deskovku
                    </a>{" "}
                    na víkend,{" "}
                    <a
                      href="https://www.doktorkladivo.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      pořádný nářadí
                    </a>{" "}
                    či{" "}
                    <a
                      href="https://www.e-pneumatiky.cz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      pneumatiky
                    </a>{" "}
                    na léto.
                  </li>
                  <li>
                    Naši zákazníci umí spoustu parádních věcí a my chceme, aby
                    se na ně mohli soustředit. Díky tvé péči to půjde a nebudou
                    se muset starat o&nbsp;technický provoz jejich projektů.
                  </li>
                  <li>
                    My všichni v igloo používáme spoustu interních systémů,
                    které musí spolehlivě fungovat.
                  </li>
                  <li>
                    Servery, virtualy, kontejnery… všichni potřebují spoustu
                    lásky. Naše technologie s tebou nezakrní a budou dál na
                    špičce.
                  </li>
                </ul>
              </Content>

              <Content>
                <TitleH2Center>Je práce pro tebe?</TitleH2Center>
                <p>
                  <strong>Tvůj denní chleba bude</strong>
                </p>
                <p>
                  <cite>
                    Raději osvětlím: tohle je cílová linka, né něco, co musíš
                    umět při nástupu. Zároveň se ale můžeš těšit, že s tímto se
                    u nás setkáš. Vše uvedené v reálu opravdu používáme.
                  </cite>
                </p>
                <ul>
                  <li>Ansible role playing a Terraforming.</li>
                  <li>Zkoumání a nasazování nových technologií.</li>
                  <li>
                    Programování a skriptování v <em>Pythonu</em>,{" "}
                    <em>Bashi</em>, <em></em>AWK, <em>jq</em>…
                  </li>
                  <li>
                    Dokumentace postupů a rozvoj naší interní <em>Org-mode</em>{" "}
                    wiki a nápovědy.
                  </li>
                  <li>
                    Konfigurace, testování a optimalizace <em>Apache</em>,{" "}
                    <em>PHP</em>, <em>Nginx</em>, <em>MariaDB</em>,{" "}
                    <em>Redis</em>, <em>RabbitMQ</em>, <em>InnoDB cluster</em>…
                  </li>
                  <li>
                    Opečovávní našeho privátního Mraku na <em>OpenNebule</em>,
                    ladění <em>Cephu</em>, <em>KVM</em>, <em>LXC</em>{" "}
                    kontejnerů…
                  </li>
                  <li>
                    Úpravy a optimalizace interních systémů (<em>Icinga2</em>,{" "}
                    <em>ELK</em>, <em>InfluxDB</em>, <em>Grafana</em>,{" "}
                    <em>Gitlab CI</em>…).
                  </li>
                  <li>Aktualizace, aktualizace a zase aktualizace.</li>
                  <li>Sledování logů, řešení trablů.</li>
                </ul>
                <p>
                  <strong>Na čem nám záleží</strong>
                </p>
                <ul>
                  <li>
                    <strong>Je na tebe spoleh. Bez výmluv.</strong>
                  </li>
                  <li>Dobře znáš Linux, ideálně Debian.</li>
                  <li>
                    Zvládneš se rychle naučit nové věci. A ještě rychleji ti
                    docvakne proč.
                  </li>
                  <li>
                    Umíš programovat, je jedno v čem. Nejde nám o jazyk, ale o
                    myšlení. Znáš datové typy a víš, jak s nimi pracovat.
                  </li>
                  <li>
                    Dokážeš vyřešit problém. Prozkoumat, rozebrat, sepsat,
                    vyřešit a zdokumentovat. Nejen ten, který už znáš.
                  </li>
                  <li>
                    Bez problémů řešíš dokumentaci. Tak, aby jí ostatní
                    rozuměli. Nemusíme tě do toho kopat.
                  </li>
                  <li>
                    Zvládneš debugovat problémy. Pokud něco nejede, dokážeš
                    rychle zúžit možný problém, použít vhodný nástroj a dobrat
                    se řešení nebo hotfixu.
                  </li>
                  <li>Nechceš zamrznout v čase a jdeš si za svým lepším já.</li>
                  <li>
                    Vnímáš, že bezpečnost je důležitá. Neděláš v ní kompromisy.
                  </li>
                  <li>
                    Angličtina: čtení technické dokumentace, domluvíš se alespoň
                    psanou formou.
                  </li>
                </ul>
                <p>
                  <strong>Na čem nám naopak nezáleží</strong>
                </p>
                <ul>
                  <li>
                    Kolik je ti zim, jestli čůráš ve stoje nebo v sedě, jak se
                    oblékáš, s kým chceš žít nebo kdo jsou tví rodiče.
                  </li>
                  <li>
                    Na tvém vzdělání. Fakt ne. Ani na maturitě, ani na titulech,
                    ani na certifikátech čí diplomech (s výjimkou <i>BDP/BSc</i>
                    ).
                  </li>
                  <li>Na nedostatku formální praxe či zářivých referencích.</li>
                  <li>
                    Na tom, že splňuješ všechno z předchozího odstavce. Stačí si
                    věřit, že je s naší pomocí postupně zmákneš.
                  </li>
                </ul>
                <p>
                  <strong>Naše sny</strong>
                </p>
                <ul>
                  <li>
                    Máš dobrý vztah k Free software. Když najdeš bug, tak jej i
                    nahlásíš a pomůžeš s&nbsp;řešením.
                  </li>
                  <li>
                    Dřív tě zaměstnávalo programování v{" "}
                    <code>((Python|Ruby|Haskell|elisp|.+) )+</code>.
                  </li>
                  <li>
                    Zvládneš se se zákazníkem domluvit na vhodném řešení,
                    přinejhorším i po telefonu.
                  </li>
                  <li>
                    Vyznáš se v{" "}
                    <code>
                      ((L[AN]MP)|Redis|(Postgre|My)SQL|(Maria|Mongo)DB|rsyslogu|Icinze|HAProxy|Xenu|kvm|Dockeru)+
                    </code>
                    .
                  </li>
                  <li>
                    Jsi{" "}
                    <a
                      href="//github.com/emacs-evil/evil/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      evil
                    </a>{" "}
                    v (
                    <a href="//spacemacs.org/" target="_blank" rel="noreferrer">
                      spac
                    </a>
                    )?
                    <a
                      href="//gnu.org/software/emacs/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      emacsu
                    </a>
                    .
                  </li>
                  <li>
                    Bez{" "}
                    <a href="//orgmode.org/" target="_blank" rel="noreferrer">
                      Org-mode
                    </a>{" "}
                    se nehneš.
                  </li>
                  <li>
                    Baví tě sdílet své znalosti, přednášet nebo psát na{" "}
                    <Link to="/blog/">blog</Link>.
                  </li>
                </ul>
              </Content>
              <PaddingHalfWrapper>
                <Content>
                  <PaddingHalfWrapper>
                    <TitleH2Center>Co se u nás naučíš?</TitleH2Center>
                  </PaddingHalfWrapper>
                  <p>
                    Toho bude spoustu, ať už toho teď umíš, kolik chceš.
                    Ověřených věcí se držíme, ale vždy hledáme cesty ke
                    zlepšení. Zároveň máme rozjetých spoustu interních
                    miniprojektů, kterými se posouváme dál.
                  </p>
                  <p>
                    Namátkou pár věcí, se kterými se v nejbližší době asi
                    potkáš:
                  </p>
                  <ul>
                    <li>Automatizovat správu serverů přes Ansible.</li>
                    <li>Vytvářet checky pro Icingu.</li>
                    <li>Pracovat se spacemacsem, Org-mode a nástroji kolem.</li>
                    <li>
                      CEPH, KVM, OpenNebula, ZFS, Btrfs, GlusterFS, Open
                      vSwitch, InnoDB cluster, MongoDB, RabbitMQ, Redis a
                      Haproxy.
                    </li>
                    <li>
                      Spousta věcí z oblasti webhostingu, domén a certifikátů.
                    </li>
                  </ul>
                </Content>
              </PaddingHalfWrapper>

              <PaddingHalfWrapper>
                <TitleH2Center>Co tě u nás čeká?</TitleH2Center>
                <p>
                  Nehledáme supermana s nadlidskými schopnostmi. Chceme pracovat
                  s někým, koho baví Linux a postupně se dostane do všeho, co u
                  nás děláme. Toužíme po lenochovi, který si vše zautomatizuje.
                </p>
                <p>
                  Staráme se o tři hlavní oblasti: Webhosting, infrastruktura
                  pro virtuální servery a řešení na míru pro nás (hosting); naše
                  vývojáře, naše markeťáky; a projekty našich zákazníků.
                </p>
                <p>
                  Do kanclu chodíme běžně pondělí až středa, zbytek na HO.
                  Pracovní doba je na společné domluvě. Na serverech máme sice
                  Debian, ale na svojí mašině můžeš mít, co chceš.
                </p>
                <p>
                  Postupně tě seznámíme s našimi postupy, naučíme tě, co neumíš,
                  naučíme se od tebe, co neumíme my, a dle toho, jak to půjde,
                  se rovnou pustíme do práce.
                </p>
                <p>
                  Fungujeme hodně otevřeně, směr našeho oddělení vymýšlíme
                  všichni dohromady, stejně jako způsob práce. Pro sledování
                  úkolů používáme Redmine a pro organizaci práce Kanban. Máme
                  rádi svobodný software, snažíme se zveřejňovat naše úpravy,
                  pluginy i nástroje, které může použít někdo další.
                </p>
                <p>
                  I přesto, že je adminování hodně o rutině, zkoušíme každému
                  postkynout různorodou práci a najít dostatek prostoru na věci,
                  které ho baví víc.
                </p>
                <p>
                  Je toho ještě spousta, nejlepší bude si to nechat na pohovor.
                </p>
              </PaddingHalfWrapper>

              <PaddingHalfWrapper>
                <TitleH2Center>Co dostaneš za benefity?</TitleH2Center>
                <Benefits />
              </PaddingHalfWrapper>

              <Slider
                title={"S kým budeš pracovat?"}
                data={personSlider}
                style={{ minHeight: "28rem" }}
              />
            </JobDetailContent>

            <ContactFormJobs heading="Napiš Kepimu a zbytečně to neodkládej" />
          </JobDetailBody>
        </JobDetailWrapper>
      </JobDetailMainWrapper>
    </ThemeProvider>
  )
}

export default LinuxAdminKaJobDetail
